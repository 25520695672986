import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    // 定义的token字段
    state: {
        token: '',
    },
    mutations: {
        // 修改touken
        modifyToken(state,data) {
            state.token = data
        },
        deleteToken(state){
            state.token = ''
        }
    },
})
export default store;