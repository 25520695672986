<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Home from './components/home.vue'
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
div {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}
input{
  outline: none;
  background: transparent;
  border: none;
  outline: medium;
}
</style>
