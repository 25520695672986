import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import VueRouter from 'vue-router'
import router from '../src/router/router.js'
import 'element-ui/lib/theme-chalk/index.css';
import store from '../src/store/store'
import request from "./utils/request";
Vue.prototype.request = request
Vue.prototype.$store = store
Vue.use(Element)
Vue.use(VueRouter);
Vue.prototype.URL = 'https://mba.xdgm21.com'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
export default Vue
