import VueRouter  from "vue-router";
import Vue  from "vue";
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "login",
        component: () => import("../login/index.vue"),
        meta: {
            title: '登录'
        }
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../home/index.vue"),
        meta: {
            title: '首页'
        }
    },
];

const router = new VueRouter({
    routes
});

export default router;