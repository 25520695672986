<template>
    <div class="bg_img">
      11111111
    </div>
  </template>
  
  <script>
  export default {
    components: {
  
    },
    data() {
      return {
       
      };
    },
  }
  </script>
  
  <style>
  
  </style>
  